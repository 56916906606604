import React from "react"
import Layout from "../components/layout"
import Button from "@material-ui/core/Button"
import Footer from "../components/footer"

export default function Home(props) {
  return (
    <Layout>
      <h1>Page Not Found</h1>
      <Button variant="contained" color="primary" size="large" href="/">
        Go to Home
      </Button>
      <Footer />
    </Layout>
  )
}
